<template>
  <div>
    <div v-if="list.length === 0">
      <div class="no-redeem py-3">ไม่พบประวัติการแลก</div>
    </div>
    <div class="content-container-box" v-else>
      <div
        class="m-2 content-reward-group content-privilege-group-fade1"
        v-for="(item, index) in list"
        :key="index"
      >
        <div>
          <b-row class="line-top py-2">
            <b-col cols="5">
              <b-img-lazy
                v-if="item.image_url"
                v-bind="mainProps"
                :src="item.image_url"
                class="lazy-image"
                alt="Image 1"
              ></b-img-lazy>
              <b-img-lazy
                v-else
                v-bind="mainProps"
                :src="require('@/assets/images/default-image.png')"
                class="lazy-image"
                alt="Image 1"
              ></b-img-lazy>
            </b-col>
            <b-col>
              <div class="title">{{ item.name }}</div>
              <div class="description-page mt-1">{{ item.detail }}</div>
            </b-col>
          </b-row>
          <b-row class="px-2">
            <b-col cols="7" class="py-2">
              <div class="until-date">
                วันที่หมดอายุ: {{ dateFormat(item.expired_date) }}
              </div>
            </b-col>
            <b-col cols="5" class="py-2 text-right">
              <div class="f-bold f-sm">ใช้งานเรียบร้อย</div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    list: {
      required: true
    }
  },
  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: false,
        blank: true,
        blankColor: "#bbb",
        width: 600,
        height: 600,
        class: ""
      }
    };
  },
  methods: {
    dateFormat(date) {
      return moment(date).locale("th").format("lll").replace("เวลา", "");
    },
    seeDetail(item) {
      if (item.is_previlege_coupon) {
        this.$router.push(`/privilegehistory/${item.privilege_id}`);
      } else {
        this.$router.push(`/couponhistory/${item.promotion_id}`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.total-point {
  border-bottom: 1px solid gray;
}
.point {
  font-size: var(--text-md);
}
.totalpoint-font {
  font-weight: 600;
  font-size: var(--text-xl);
}
.lazy-image {
  font-size: 1px;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.content-reward-group {
  background-color: #f0f0f0;
}
.title {
  font-size: var(--text-lg);
  font-weight: 600;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.until-date {
  font-size: var(--text-sm);
}
.status-false {
  opacity: 0.6;
}
.btn-redeem {
  background-color: transparent;
  color: #000;
  border: none;
}
.line-top {
  border-bottom: 1px dashed #aaaaaa;
  margin: 0 0;
  padding: 5px 0;
  // border-bottom: 1px dashed rgb(170, 170, 170);
}
.point {
  font-size: var(--text-md);
  color: green;
}
.no-redeem {
  color: var(--text-color);
  text-align: center;
  padding: 20px 0;
}
.redeem-privilege {
  color: red;
  font-size: var(--text-sm);
  font-weight: 600;
}
::v-deep .btn {
  color: unset;
  background-color: transparent;
  border-color: transparent;
  text-align: left;
  padding: 0;
}
</style>
