var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.list.length > 0)?_c('div',{staticClass:"content-privilege"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,on:{"click":function($event){$event.preventDefault();return _vm.seeDetailPrivilege(item.promotion_id, item.user_coupon_id)}}},[_c('div',{class:[
          'm-2',
          _vm.chkExpiredDate(item.expired_date)
            ? 'content-privilege-group-fade'
            : 'content-privilege-group'
        ]},[_c('b-row',{staticClass:"h-100",attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"h-100",attrs:{"cols":"5"}},[_c('div',{staticClass:"h-100"},[(item.image_url)?_c('b-img-lazy',_vm._b({staticClass:"lazy-image",attrs:{"src":item.image_url,"alt":"Image 1"}},'b-img-lazy',_vm.mainProps,false)):_c('b-img-lazy',_vm._b({staticClass:"lazy-image",attrs:{"src":require('@/assets/images/default-image.png'),"alt":"Image 1"}},'b-img-lazy',_vm.mainProps,false))],1)]),_c('b-col',{attrs:{"cols":"7"}},[_c('b-row',{staticClass:"flex-column h-100",attrs:{"no-gutters":""}},[(item.privilege_name)?_c('div',{staticClass:"bg-privilage"},[_vm._v(" "+_vm._s(item.privilege_name)+" ")]):_vm._e(),_c('div',{staticClass:"title line-clamp-2 px-2 py-1"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"content-between mt-auto p-2"},[(_vm.chkExpiredDate(item.expired_date))?_c('div',{staticClass:"my-2 redeem-privilege f-sm"},[_vm._v(" วันหมดอายุ: "+_vm._s(item.expired_date ? _vm.dateFormat(item.expired_date) : "-")+" ")]):_vm._e(),_c('div',{staticClass:"expired-date f-sm"},[_vm._v(" วันหมดอายุ "),_c('br'),_vm._v(" "+_vm._s(item.expired_date ? _vm.dateFormat(item.expired_date) : "-")+" ")]),(
                    item.max_quantity != 0 &&
                    item.availability == 1 &&
                    item.is_from_stampcard == 0
                  )?_c('div',{staticClass:"text-right color-primary f-sm f-bold"},[_vm._v(" สิทธิคงเหลือ "),_c('br'),_c('span',[_vm._v(" "+_vm._s(item.quantity_used)+"/"+_vm._s(_vm.kFormatter(item.max_quantity)))])]):_vm._e()])])],1)],1)],1)])}),0):_c('div',[_c('div',{staticClass:"text-center py-3 notfound-data"},[_vm._v("ไม่พบข้อมูลสิทธิพิเศษ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }