<template>
  <div>
    <div v-if="list.length > 0" class="content-privilege">
      <div
        v-for="(item, index) in list"
        :key="index"
        @click.prevent="
          seeDetailPrivilege(item.promotion_id, item.user_coupon_id)
        "
      >
        <div
          :class="[
            'm-2',
            chkExpiredDate(item.expired_date)
              ? 'content-privilege-group-fade shadow-sm'
              : 'content-privilege-group shadow-sm'
          ]"
        >
          <b-row no-gutters class="h-100">
            <b-col cols="5" class="h-100">
              <div class="h-100">
                <b-img-lazy
                  v-if="item.image_url"
                  v-bind="mainProps"
                  :src="item.image_url"
                  class="lazy-image"
                  alt="Image 1"
                ></b-img-lazy>
                <b-img-lazy
                  v-else
                  v-bind="mainProps"
                  :src="require('@/assets/images/default-image.png')"
                  class="lazy-image"
                  alt="Image 1"
                ></b-img-lazy>
              </div>
            </b-col>
            <b-col cols="7">
              <b-row class="flex-column h-100" no-gutters>
                <div v-if="item.privilege_name" class="bg-privilage">
                  {{ item.privilege_name }}
                </div>
                <div v-else class="mb-2"></div>
                <div class="title line-clamp-2 px-2 py-1">{{ item.name }}</div>
                <div class="description line-clamp-2 px-2">
                  {{ item.detail }}
                </div>
                <div class="content-between mt-auto p-2">
                  <b-badge variant="expired" v-if="item.expired_date">
                    หมดอายุ :
                    {{
                      $moment(item.expired_date)
                        .add(543, "y")
                        .format("DD MMM YYYY")
                    }}
                  </b-badge>
                  <div
                    class="text-right color-primary f-sm f-bold text-nowrap"
                    v-if="
                      item.max_quantity != 0 &&
                      item.availability == 1 &&
                      item.is_from_stampcard == 0
                    "
                  >
                    คงเหลือ
                    <br />
                    <span>
                      {{ item.quantity_used }}/{{
                        kFormatter(item.max_quantity)
                      }}</span
                    >
                    <!-- <span v-else>ไม่จำกัด</span> -->
                  </div>
                </div>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="text-center py-3 notfound-data">ไม่พบข้อมูลสิทธิพิเศษ</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  components: {
    moment
  },
  props: {
    list: {
      required: true
    }
  },
  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: false,
        blank: true,
        blankColor: "#bbb",
        width: 300,
        height: 300,
        class: ""
      }
    };
  },
  methods: {
    seeDetailPrivilege(id, coupon_id) {
      this.$router.push("/privilege/" + id + "?user_coupon_id=" + coupon_id);
    },
    dateFormat(date) {
      return moment(date).locale("th").format("lll").replace("เวลา", "");
    },
    chkExpiredDate(date) {
      const currentDate = new Date();
      const privilegeDate = new Date(date);
      const sameDate = currentDate.getTime() > privilegeDate.getTime();
      return sameDate;
    },
    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    }
  }
};
</script>

<style lang="scss" scoped>
.line-clamp1 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.ft-12 {
  font-size: var(--text-sm);
}
.lazy-image {
  font-size: 1px;
  // -o-object-fit: cover;
  // object-fit: cover;
  // object-fit: contain;
  width: 100%;
  height: auto;
  aspect-ratio: 1;
}
.content-privilege-group {
  background-color: white;
  border-radius: 10px;
  height: unset;
}
.content-privilege-group-fade {
  background-color: white;
  opacity: 0.5;
}
.line-top {
  border-bottom: 1px dashed #aaaaaa;
  margin: 0 0;
  padding: 5px 0;
}
.list-pri-content {
  background-color: #fff;
  margin: 1rem;
  border-radius: 5px;
  padding: 10px 0;
}
.title {
  font-size: var(--text-lg);
  font-weight: 600;
}

.line-clamp-2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.expired-date {
  color: #9f9f9f;
}
.see-all {
  font-size: var(--text-md);
  font-weight: 600;
  text-decoration: underline;
}
.border-r {
  border-right: 2px dashed #d7d7d7;
}
.radius-bd {
  border-radius: 5px;
}
.btn-redeem {
  background-color: transparent;
  color: #000;
  border: none;
}
.redeem-privilege {
  color: red;
  font-size: var(--text-sm);
  font-weight: 600;
}
.tag-second {
  color: #000;
  background-color: #fcbd00;
  border: 1px solid #fcbd00;
  font-size: var(--text-sm);
  padding: 0 5px;
}
.tag {
  font-size: var(--text-sm);
  font-weight: normal;
  line-height: normal;
  border-radius: 0px;
}
.privilege-gift {
  background-color: #fcbd00;
  border: 1px solid #fcbd00;
}
.gift-content {
  margin: auto 0px;
}

.bg-privilage {
  width: 100%;
  //   max-width: 80%;
  background: var(--primary-color);
  color: var(--font-primary-color);
  padding: 0 10px;
  border-radius: 0 10px 0 0;
  font-size: var(--text-md);
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
// .privilege-items {
//   background: white;
//   border-radius: 10px;
// }
.expire-date {
  color: var(--danger);
  border-radius: 0.25rem;
  border: 1px solid var(--danger);
}
</style>
