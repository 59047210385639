<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else>
      <div
        class="d-flex align-items-center bar-color"
        style="border-bottom: 1px solid #e4e4e4"
      >
        <div
          class="f-2xl px-3 py-2 f-bold w-100"
          style="border-right: 1px solid #e4e4e4"
        >
          สิทธิพิเศษ
        </div>
        <div class="text-center px-3" @click="getRedeemCode">
          <font-awesome-icon icon="gift" size="lg" />
        </div>
      </div>

      <div>
        <div class="title-privilege">
          <b-tabs v-model="tabIndex" nav-class="shadow-sm" content-class="mt-3">
            <b-tab>
              <template #title>
                <strong>ทั้งหมด</strong>
                <b-badge
                  v-if="privilegeList.length > 0"
                  variant="color-primary"
                  class="ml-1"
                  pill
                  >{{ privilegeList.length }}</b-badge
                >
              </template>
              <PrivilegeListNewLayout
                :list="privilegeList"
                v-if="$store.state.theme.line_layout == 'Compact'"
              />
              <PrivilegeListComponent :list="privilegeList" v-else />
            </b-tab>
            <b-tab title="ประวัติการใช้">
              <div class="list-redeem-count">
                <div class="mr-auto">จำนวนรายการ</div>
                <div class="ml-auto">{{ privilegeHistory.length }} รายการ</div>
              </div>

              <PrivilegeHistoryNewLayout
                :list="privilegeHistory"
                v-if="$store.state.theme.line_layout == 'Compact'"
              />
              <PrivilegeHistory :list="privilegeHistory" v-else />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    <ModalRedeemCode ref="ModalRedeemCode" @hideSuccess="refreshPage" />
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import PrivilegeListComponent from "@/components/privilege/PrivilegeListComponent";
import PrivilegeListNewLayout from "@/components/privilege/PrivilegeListNewLayout";
import OtherLoading from "@/components/other/OtherLoading";
import PrivilegeHistory from "@/components/privilege/detail/PrivilegeHistory";
import PrivilegeHistoryNewLayout from "@/components/privilege/detail/PrivilegeHistoryNewLayout";
import ModalRedeemCode from "@/components/profile/modal/ModalRedeemCode";
export default {
  components: {
    Breadcrumb,
    PrivilegeListComponent,
    PrivilegeListNewLayout,
    OtherLoading,
    PrivilegeHistory,
    PrivilegeHistoryNewLayout,
    ModalRedeemCode
  },

  metaInfo() {
    return {
      title: this.$route.query.title || "Privilege"
    };
  },
  async created() {
    await this.liffLogin();
    if (this.$route.query) {
      if (parseInt(this.$route.query.tabIndex) === 1) {
        this.tabIndex = 1;
      }
    }
  },
  data() {
    return {
      filter: {
        SocialProvider: "line",
        SocialId: ""
      },
      breadcrumbList: [
        {
          text: "สิทธิพิเศษ",
          to: "/profile"
        }
      ],
      privilegeList: {},
      isLoading: true,
      notFoundData: false,
      privilegeHistory: {},
      tabIndex: 0,
      lineProfile: this.$cookies.get("user_profile_token")
    };
  },
  methods: {
    async liffLogin() {
      if (this.$liff.isLoggedIn()) {
        if (!this.$cookies.get("user_profile_token")) {
          this.getProfileList();
        } else {
          this.LoginExternal();
        }
      } else {
        this.$liff.login({
          redirectUri: this.$Endpoint + "privilege"
        });
      }
    },
    getProfileList() {
      this.$liff.getProfile().then(profile => {
        this.$cookies.set("user_profile_token", profile, 60 * 60 * 24 * 30);
        this.lineProfile = profile;
        this.LoginExternal();
      });
    },
    getRedeemCode: async function () {
      await this.$refs.ModalRedeemCode.show();
    },
    async LoginExternal() {
      this.filter.SocialId = this.lineProfile.userId;
      this.$axios
        .post(
          `${process.env.VUE_APP_API}/api/v1/user/LoginExternal`,
          this.filter
        )
        .then(async data => {
          if (data.result == 0) {
            this.$cookies.remove("hourglass_register_success");
            this.$cookies.remove("hourglass_token");
            this.$router.push("/validatetelephone");
          } else {
            await this.$cookies.set("hourglass_token", data.detail.token);
            // await this.getUserProfile();
            await this.getCouponList();
            await this.getCouponHistory();
          }
        });
    },
    async getCouponList() {
      this.isLoading = true;
      this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/Privilege/privilege_list`)
        .then(async data => {
          if (data.result == 1) {
            this.privilegeList = data.detail.filter(el => {
              if (el.availability == 1) {
                return el;
              } else {
                if (el.already_use == 0) {
                  return el;
                }
              }
            });
            this.privilegeList.sort(
              (a, b) =>
                new Date(b.expired_date) - new Date(a.expired_date) ||
                b.availability - a.availability
            );
          }
          this.isLoading = false;
        });
    },
    // async getCouponHistory() {
    //   this.axios(`/api/v1/Privilege/privilege_history`).then(async data => {
    //     this.isLoading = false;
    //     if (data.result == 1) {
    //       this.privilegeHistory = data.detail;
    //     }
    //   });
    // },
    async getCouponHistory() {
      this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/Privilege/privilege_history`)
        .then(async data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.privilegeHistory = data.detail;
          }
        });
    },
    async refreshPage() {
      await this.$store.dispatch("refreshUserProfile");
      await this.getCouponList();
      await this.getCouponHistory();
    }
  }
};
</script>

<style lang="scss" scoped>
.bg-black {
  background-color: #000;
  min-height: 100vh;
}
.remark {
  color: #000;
  font-weight: 600;
  font-size: var(--text-xl);
}
.title {
  color: #fff;
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
  top: 80px;
}
.icon-history-custom {
  color: #fff;
  text-align: right;
  margin-top: -31px;
  margin-bottom: 5px;
  right: 15px;
}

::v-deep li.nav-item {
  width: 50%;
  text-align: center;
}

::v-deep a {
  color: #fff;
}
.position-enter-code {
  position: absolute;
  top: 20px;
}
.icon-redeem {
  position: absolute;
  top: 45%;
  right: 0;
  border-radius: 5px;
  color: var(--secondary-color);
  z-index: 2;
  transform: translateY(-55%);
}
</style>
