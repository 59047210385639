<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="ModalRedeemCode"
      hide-header
      hide-footer
      centered
      @hidden="hide"
    >
      <div class="text-center ft-bold f-xl mb-2">PRIVILEGE CODE</div>

      <div class="mb-2">
        <InputText
          v-model="$v.form.code.$model"
          textFloat="โค้ดสิทธิพิเศษ"
          placeholder="กรุณาใส่โค้ดสิทธิพิเศษ"
          type="text"
          name="redeem_code"
          size="lg"
          @input="invalidCode = false"
          isRequired
          :isValidate="invalidCode || $v.form.code.$error"
          :v="$v.form.code"
          class="f-regular"
        />
        <span class="text-error f-md" v-if="invalidCode">
          โค้ดสิทธิพิเศษไม่ถูกต้อง
        </span>
      </div>
      <div class="text-center">
        <b-button @click="getRedeem" block variant="confirm-primary">
          ยืนยัน
        </b-button>
        <b-button
          block
          variant="outline-primary"
          class="mt-2"
          @click="showModal = false"
        >
          ยกเลิก
        </b-button>
      </div>
    </b-modal>
    <ModalAlert ref="modalAlert" :text="msg" @hide="hideSuccess" />
    <ModalRedeemError ref="ModalRedeemError" :text="msg" />
    <ModalLoading ref="modalLoading" />
  </div>
</template>

<script>
import InputText from "@/components/input/InputText";
import { required, helpers } from "vuelidate/lib/validators";
import ModalAlert from "@/components/alert-modal/ModalAlert";
import ModalLoading from "@/components/other/ModalLoading.vue";
import ModalRedeemError from "@/components/profile/modal/ModalRedeemError";
const alphaNumEng = helpers.regex("alpha", /^[0-9a-zA-Z-_]*$/);
export default {
  components: {
    InputText,
    ModalAlert,
    ModalRedeemError,
    ModalLoading
  },
  data() {
    return {
      showModal: false,
      form: {
        code: ""
      },
      msg: "",
      validationFirst: true,
      invalidCode: false
    };
  },
  validations() {
    return {
      form: {
        code: { required, alphaNumEng }
      }
    };
  },
  methods: {
    show() {
      this.showModal = true;
      this.form.code = "";
      this.$v.form.$reset();
      this.$emit("show");
    },
    hide() {
      this.showModal = false;
      this.form.code = "";
      this.$emit("hide");
      this.$v.$reset();
    },
    getRedeem: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$refs.modalLoading.show();

      this.$axios
        .post(
          `${process.env.VUE_APP_API}/api/v1/privilege/redeemprivilege`,
          this.form
        )
        .then(async data => {
          this.$refs.modalLoading.hide();
          if (data.result == 1) {
            this.$refs.modalAlert.show();
            this.msg = data.message;
          } else {
            if (data.message == "ไม่พบสิทธิพิเศษ") {
              return (this.invalidCode = true);
            }
            this.$refs.ModalRedeemError.show();
            this.msg = data.message;
          }
        });
    },
    hideSuccess: function () {
      console.log("asdf");
      this.showModal = false;
      this.$emit("hideSuccess");
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-confirm-redeem {
  width: 100%;
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 5px;
  height: 45px;
}
.ft-bold {
  font-weight: bold;
}
</style>
